import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Content, IconActionCheck, Space, Button, Heading, Link } from '@hse-design/react';
import { Playground, Props } from 'docz';
import { P, Link as CommonLink, ComponentLinks } from '../common';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ContentHeading = makeShortcode("ContentHeading");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "heading"
    }}>{`Heading`}</h1>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`import { Heading } from '@hse-design/react';
`}</code></pre>
    <ComponentLinks figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=1204%3A4665'} storybook={'/?path=/story/content-content--playground'} name={'Heading'} vueStorybook={'/?path=/story/content-content--playground'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`Heading – это компонент заголовков различного уровня.`}</p>
    <p>{`Уровень заголовка задается пропом `}<inlineCode parentName="p">{`size`}</inlineCode>{`. Возможные значения – `}<inlineCode parentName="p">{`h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`h2`}</inlineCode>{`, `}<inlineCode parentName="p">{`h3`}</inlineCode>{`, `}<inlineCode parentName="p">{`h4`}</inlineCode>{`.`}</p>
    <p>{`Стили заголовков соответствуют токенам `}<inlineCode parentName="p">{`text_heading_h1`}</inlineCode>{`, `}<inlineCode parentName="p">{`text_heading_h2`}</inlineCode>{`, `}<inlineCode parentName="p">{`text_heading_h3`}</inlineCode>{`, `}<inlineCode parentName="p">{`text_heading_h4`}</inlineCode>{`.`}</p>
    <p>{`При использовании контентного варианта компонента (`}<inlineCode parentName="p">{`ContentHeading`}</inlineCode>{`) добавляются внешние вертикальные отступы`}</p>
    <Playground __position={1} __code={'<Heading size=\"h1\">Заголовок 1 уровня</Heading>\n<Space vertical size=\"medium\" />\n<Heading size=\"h3\">Заголовок 2 уровня</Heading>\n<Space vertical size=\"medium\" />\n<Heading size=\"h3\">Заголовок 3 уровня</Heading>\n<Space vertical size=\"medium\" />\n<Heading size=\"h4\">Заголовок 4 уровня</Heading>'} __scope={{
      props,
      DefaultLayout,
      Content,
      IconActionCheck,
      Space,
      Button,
      Heading,
      Link,
      Playground,
      Props,
      P,
      CommonLink,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Heading size="h1" mdxType="Heading">Заголовок 1 уровня</Heading>
  <Space vertical size="medium" mdxType="Space" />
  <Heading size="h3" mdxType="Heading">Заголовок 2 уровня</Heading>
  <Space vertical size="medium" mdxType="Space" />
  <Heading size="h3" mdxType="Heading">Заголовок 3 уровня</Heading>
  <Space vertical size="medium" mdxType="Space" />
  <Heading size="h4" mdxType="Heading">Заголовок 4 уровня</Heading>
    </Playground>
    <h2 {...{
      "id": "примеры"
    }}>{`Примеры`}</h2>
    <h3 {...{
      "id": "использование-на-контентных-страницах"
    }}>{`Использование на контентных страницах`}</h3>
    <p>{`При использовании контентного варианта компонента (`}<inlineCode parentName="p">{`ContentHeading`}</inlineCode>{`) добавляются внешние вертикальные отступы`}</p>
    <Playground __position={2} __code={'<ContentHeading size=\"h1\">Заголовок 1 уровня</ContentHeading>\n<ContentHeading size=\"h2\">Заголовок 2 уровня</ContentHeading>\n<ContentHeading size=\"h3\">Заголовок 3 уровня</ContentHeading>\n<ContentHeading size=\"h4\">Заголовок 4 уровня</ContentHeading>'} __scope={{
      props,
      DefaultLayout,
      Content,
      IconActionCheck,
      Space,
      Button,
      Heading,
      Link,
      Playground,
      Props,
      P,
      CommonLink,
      ComponentLinks,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <ContentHeading size="h1" mdxType="ContentHeading">Заголовок 1 уровня</ContentHeading>
  <ContentHeading size="h2" mdxType="ContentHeading">Заголовок 2 уровня</ContentHeading>
  <ContentHeading size="h3" mdxType="ContentHeading">Заголовок 3 уровня</ContentHeading>
  <ContentHeading size="h4" mdxType="ContentHeading">Заголовок 4 уровня</ContentHeading>
    </Playground>
    <h3 {...{
      "id": "пропы"
    }}>{`Пропы`}</h3>
    <Props of={Heading} mdxType="Props" />
    <p>{`Все остальные пропы пробрасываются на корневой элемент.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      